.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 416px;
  padding: 8px;
}

.content {
  flex: 1 0 auto;
  margin-bottom: 16px;
}

.footer {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  padding-bottom: 48px;
}
